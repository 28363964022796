<template>
	<div>
		<div class="container" style="margin-top: 5%;margin-left: 1%;margin-right: 1%;">
			<div class="handle-box">
				<el-input v-model="query.title" clearable style="width: 150px;" placeholder="标题" class="handle-input mr10"></el-input>
				<el-select placeholder="状态" clearable @change="clickSearch" v-model="query.status" style="width: 150px;" class="handle-input mr10">
					<el-option label="等待财务打款" :value="0"></el-option>
					<el-option label="财务已打款" :value="1"></el-option>
					<el-option label="客户已确认" :value="2"></el-option>
					<el-option label="交易完成" :value="3"></el-option>
					<el-option label="交易关闭" :value="-1"></el-option>
				</el-select>
				<el-button type="primary" icon="el-icon-search" @click="clickSearch">搜索</el-button>
			</div>
			<el-table ref="myTable" :data="tableData" border>
				<el-table-column
						type="selection"
						width="45">
				</el-table-column>
				<el-table-column prop="title" label="标题"></el-table-column>
				<el-table-column prop="statementCounts" label="结算人数"></el-table-column>
				<el-table-column prop="sumAmount" label="总金额"></el-table-column>
				<el-table-column prop="sumTaxesFare" label="总税费"></el-table-column>
				<el-table-column prop="sumServiceFare" label="总手续费"></el-table-column>
				<el-table-column prop="sumTotalFare" label="最终打款金额"></el-table-column>
				<el-table-column prop="createTime" label="创建时间"></el-table-column>
				<el-table-column label="状态">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 0">等待财务打款</span>
						<span v-if="scope.row.status == 1">财务已打款</span>
						<span v-if="scope.row.status == 2">客户已确认</span>
						<span v-if="scope.row.status == 3">交易完成</span>
						<span v-if="scope.row.status == -1">交易关闭</span>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="text" @click="openDetailDialig(scope.row.id)">详情</el-button>
						<el-button type="text" v-if="scope.row.status==1" @click="checkClick(scope.row.id)">确认收款</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div class="pagination">
				<el-pagination background layout="sizes,total, prev, pager, next"
							   :page-sizes="pageSize"
							   @size-change="handleSizeChange"
							   :current-page="query.pageIndex" :page-size="query.pageRows"
				 :total="pageTotal" @current-change="handlePageChange" prev-click="pageChange(1)" next-click="pageChange(2)"></el-pagination>
			</div>
		</div>

		<el-dialog title="详情" :visible.sync="detailDialog" width="60%">
			<div class="grey-line">
			<el-row class="statement_list">
				<div class="grid-content">
					<div class="lost-title">{{detailData.title}}</div>
				</div>
			</el-row>
			<el-divider content-position="left">流水信息</el-divider>
			<el-table :data="detailData.statements" border height="300" show-summary :summary-method="getSummaries">
			    <el-table-column prop="workDateByStr" width="100" label="日期"></el-table-column>
			    <el-table-column prop="driverName" width="90" label="司机姓名"></el-table-column>
			    <el-table-column prop="driverPhone" label="司机电话"></el-table-column>
			    <el-table-column prop="vehicleCode"  label="车牌号"></el-table-column>
			    <el-table-column prop="vehicleModel" label="车型"></el-table-column>
			    <el-table-column prop="regionName" label="营运区域"></el-table-column>
			    <el-table-column prop="reckonType" width="90" label="结算方式">
			    	<template slot-scope="scope">
			    		<span v-if="scope.row.reckonType==1">点位结算</span>
			    		<span v-if="scope.row.reckonType==2">件数结算</span>
			    		<span v-if="scope.row.reckonType==3">吨位结算</span>
			    		<span v-if="scope.row.reckonType==4">公里结算</span>
			    	</template>
			    </el-table-column>
				 <el-table-column prop="amount" width="90" label="营运金额"></el-table-column>
				<el-table-column prop="taxesFare" label="税费"></el-table-column>
				<el-table-column prop="serviceFare" label="手续费"></el-table-column>
				<el-table-column prop="totalFare" label="最终打款金额"></el-table-column>
			  </el-table>
			  <el-divider content-position="left">打款信息</el-divider>
			  <el-row>
			  	<el-col :span="4">
			  		<div class="grid-content">
			  			<div class="complaint-title">人数:</div>
			  			<div class="title-content">
			  				{{detailData.statementCounts}}
			  			</div>
			  		</div>
			  	</el-col>
				<el-col :span="5">
					<div class="grid-content">
						<div class="complaint-title">总金额:</div>
						<div class="title-content">
							{{detailData.sumAmount}}
						</div>
					</div>
				</el-col>
				<el-col :span="5">
					<div class="grid-content">
						<div class="complaint-title">总税费:</div>
						<div class="title-content">
							{{detailData.sumTaxesFare}}
						</div>
					</div>
				</el-col>
				<el-col :span="5">
					<div class="grid-content">
						<div class="complaint-title">总手续费:</div>
						<div class="title-content">
							{{detailData.sumServiceFare}}
						</div>
					</div>
				</el-col>
				<el-col :span="5">
					<div class="grid-content">
						<div class="complaint-title">最终打款金额:</div>
						<div class="title-content">
							{{detailData.sumTotalFare}}
						</div>
					</div>
				</el-col>
			  </el-row>
			<el-row>
				<el-col :span="8">
					<div class="grid-content">
						<div class="complaint-title">状态:</div>
						<div class="title-content">
							<span v-if="detailData.status == 0">等待财务打款</span>
							<span v-if="detailData.status == 1">财务已打款</span>
							<span v-if="detailData.status == 2">客户已确认</span>
							<span v-if="detailData.status == 3">交易完成</span>
							<span v-if="detailData.status == -1">交易关闭</span>
						</div>
					</div>
				</el-col>
				<el-col v-if="detailData.status!=0" :span="8">
					<div class="grid-content">
						<div class="complaint-title">打款凭证:</div>
						<div class="title-content">
							<el-image v-if="detailData.paymentFile"
							     style="width: 50px; height: 50px"
							     :src="detailData.paymentFile"
								 :preview-src-list="[detailData.paymentFile]"
								 :z-index="2222">
								 <div slot="error" class="image-slot">
									 <i class="el-icon-picture-outline"></i>
								   </div>
								 </el-image>
						</div>
					</div>
				</el-col>
				<el-col v-if="detailData.status!=0" :span="8">
					<div class="grid-content">
						<div class="complaint-title">打款时间:</div>
						<div class="title-content">
							{{detailData.paymentTime}}
						</div>
					</div>
				</el-col>
			</el-row>
			<el-row v-if="detailData.status==-1">
				<el-col :span="24">
					<div class="grid-content">
						<div class="complaint-title">关闭原因:</div>
						<div class="title-content">
							{{detailData.closeReason}}
						</div>
					</div>
				</el-col>
			</el-row>
			<el-divider content-position="left">收款人信息</el-divider>
			<el-row>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">公司名称:</div>
						<div class="title-content">{{detailData.userInfo.unitName}}</div>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">公司地址:</div>
						<div class="title-content">{{detailData.userInfo.unitAddress}}</div>
					</div>
				</el-col>
			</el-row>

			<el-row>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">联系人:</div>
						<div class="title-content">{{detailData.userInfo.contactName}}</div>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">联系方式:</div>
						<div class="title-content">{{detailData.userInfo.contactWay}}</div>
					</div>
				</el-col>
			</el-row>

			<el-row>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">法人姓名:</div>
						<div class="title-content">{{detailData.userInfo.legalPersonName}}</div>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">法人身份证:</div>
						<div class="title-content">{{detailData.userInfo.legalPersonIdentity}}</div>
					</div>
				</el-col>
			</el-row>


			<el-row>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">开户行:</div>
						<div class="title-content">{{detailData.userInfo.bankName}}</div>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">开户行账号:</div>
						<div class="title-content">{{detailData.userInfo.bankAccount}}</div>
					</div>
				</el-col>
			</el-row>


			<el-row>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">营业执照:</div>
						<div class="title-content">
							<el-image
							     style="width: 50px; height: 50px"
							     :src="detailData.userInfo.businessLicense"
								 :preview-src-list="[detailData.userInfo.businessLicense]"
							     :z-index="2222">
							  <div slot="error" class="image-slot">
									<i class="el-icon-picture-outline"></i>
							  </div>
							  </el-image>
						</div>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">道路许可证:</div>
						<div class="title-content">
							<el-image
							     style="width: 50px; height: 50px"
							     :src="detailData.userInfo.roadPermit"
								 :preview-src-list="[detailData.userInfo.roadPermit]"
								 :z-index="2222">
								 <div slot="error" class="image-slot">
									 <i class="el-icon-picture-outline"></i>
							     </div>
								 </el-image>
						</div>
					</div>
				</el-col>
			</el-row>

			</div>

		  <span slot="footer" class="dialog-footer">
		    <el-button @click="detailDialog = false">关 闭</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name:"confirmPay",
		data: function() {
			return {
				query: {
					title:'',
					status:'',
					pageIndex: 1,
					pageRows: 10,
				},
				pageSize:[
				    10,20,30,40,50
				],
				tableData: [],
				pageTotal: 0,
				detailDialog:false,
				detailData:{
					statementsData:[],
					userInfo:{
					}
				},
			}
		},
		created() {
			this.handleSearch();
		},
		components:{
		},
		methods: {
			clickSearch: function() {
				this.query.pageIndex = 1;
				this.handleSearch();
			},
            handleSizeChange:function (val) {
				this.query.pageRows = val
                this.handleSearch()
            },
			handleSearch: function() {
				this.HTTP.post('/user/remitList', this.query).then(res => {
					if (res.data.code == 1) {
						this.tableData = res.data.rows
						this.pageTotal = res.data.counts
					}else{
						this.$message.error(res.data.message);
					}
				}).catch(err => {
					this.$message.error(err);
				})
			},
			handlePageChange: function(e) {
				this.query.pageIndex = e
				this.handleSearch()
			},
			pageChange: function(type) {
				if (type == 1) {
					this.query.pageIndex -= 1;
				} else {
					this.query.pageIndex += 1;
				}
				this.handleSearch()
			},
			openDetailDialig:function(id){
				this.HTTP.get('/user/remitDetail?id='+id).then(res=>{
					if(res.data.code==1){
						this.detailDialog = true;
						this.detailData = res.data.data;
					}else{
						this.$message.error(res.data.message);
					}
				}).catch(err=>{
					this.$message.error(err);
				})
			},
			getSummaries(param) {
				const { columns, data } = param;
				const sums = [];
				columns.forEach((column, index) => {
				  if (index === 0) {
					sums[index] = '合计';
					return;
				  }
				  if(column.property=='driverName'){
					  sums[index] = data.length+"人";
					  return;
				  }
				  if(column.property=='reckonType'){
					  sums[index] = 'N/A';
					  return;
				  }
				  if(column.property=='driverPhone'){
					  sums[index] = 'N/A';
					  return;
				  }
				  const values = data.map(item => Number(item[column.property]));
				  if (!values.every(value => isNaN(value))) {
					sums[index] = values.reduce((prev, curr) => {
					  const value = Number(curr);
					  if (!isNaN(value)) {
						return prev + curr;
					  } else {
						return prev;
					  }
					}, 0);
					sums[index] += ' 元';
				  } else {
					sums[index] = 'N/A';
				  }
				});
				return sums;
			},
			checkClick:function(id){
				 this.$confirm('请确认是否已收到打款, 且金额无误?', '提示', {
					  confirmButtonText: '确定',
					  cancelButtonText: '取消',
					  type: 'warning'
				}).then(() => {
					this.HTTP.get('/user/confirmPay?id='+id).then(res=>{
						if(res.data.code==1){
							this.$message.success('操作成功');
							this.clickSearch();
						}else{
							this.$message.error(res.data.message);
						}
					}).catch(err=>{
						this.$message.error(err);
					})
				})
			}




		}
	}
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 14px;
	}

	.red {
		color: #ff0000;
	}

	.mr10 {
		margin-right: 10px;
	}

	.table-td-thumb {
		display: block;
		margin: auto;
		width: 40px;
		height: 40px;
	}

	.info-div {
		display: flex;
		justify-content: space-around;
		margin-top: 5px;
		font-size: 13px;
	}
	.info-span1 {
		width: 230px;
		font-size: 15px;
	}

	.info-span2 {
		width: 230px;
		font-size: 15px;
	}

	.info-title {
		margin-right: 4px;
		color: #000000;
	}
	.form-input{
		width: 215px;
	}

	.grey-line .el-row{
		border-bottom: 1px solid #dcdfe6;
	}
	.grey-line .el-row:last-child{
		border-bottom: none;
	}

	.statement_list .grid-content {
	    line-height: 20px;
	}

	.statement_list .lost-title {
	    font-weight: 400;
	    color: #1f2f3d;
	    font-size: 20px;
	}

	.statement_list .title-content {
	    font-weight: 400;
	    color: #1f2f3d;
	    font-size: 20px;
	    margin-left: 5px;
	}

	.grid-content {
	    margin: 10px 0;
	    display: flex;
	    flex-direction: row;
	    align-items: center !important;
	}

	.complaint-title {
	    align-items: center;
	    margin-right: 5px;
	    font-size: 16px;
	    display: flex;
		font-weight: bolder;
		color: #1f2f3d;
	    white-space: nowrap;
	}

	.title-content {
	    display: flex;
	    align-items: center;
	}
</style>
